import browserUpdate from 'browser-update/update.npm';

// import "./vendors/_vendors.browser.js";
import "./vendors/_isInViewport.js";
import "focus-visible/dist/focus-visible";
import svg4everybody from 'svg4everybody';

document.documentElement.classList.add('js');
document.documentElement.classList.remove('no-js');

svg4everybody();

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: -2, // Edge
    i: 12, // IE
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});
